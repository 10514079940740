export default {
	installerName: "安装商名称",
	zipcode: "邮编",
	country: "国家",
	state: "州",
	city: "城市",
	detailsAddress: "详细地址",
	star: "星级",
	updateTime: "更新时间",
	status: "状态",
	location: "位置",
	import: "导入",
	baseInfo: "基本信息",
	name: "姓名",
	address: "地址",
	longitude: "经度",
	latitude: "纬度",
	serviceArea: "服务区域",
	remark: "备注",
	contact: "联系人",
	phone: "电话",
	email: "电子邮件",
	job: "工作",
	explanation: "说明",
	downloadTemplate: "下载模板",
	uploadFile: "上传文件",
	successImport: "导入成功",
	continueImport: "继续导入",
	backToList: "返回列表",
	importError: "导入失败",
	errorPosition: "错误位置",
	explanationList: {
		line1: "下载模板后，请严格按照模板填写安装人员信息。",
		line2: "请勿修改模板文档格式、单元格格式、表单标题内容。",
		line3: "如果上传失败，请检查文档内容是否准确。",
		line4: "如果安装人员重复（通过姓名+邮政编码判断），将使用最新上传的数据。",
		line5: "如果同一安装人员有多个联系方式，应在联系人姓名、电话号码、职位和电子邮件单元格中使用英文格式的逗号分隔。",
		line6: "导入的安装人员默认启用。"
	},
	us: "美国",
	au: "澳大利亚",
	nz: "新西兰",
	ca: "加拿大",
	serviceScope: "服务范围",
	findInstaller: "查找安装商",
	searchByCountry: "按国家搜索",
	zipCode: "邮政编码",
	searchByAddress: "按地址搜索",
	searchByZipcode: "按邮政编码搜索",
	searchByDistance: "按距离搜索",
	km: "公里",
	miles: "{distanceMiles} 英里",
	searchByGrade: "按星级搜索",
	searchAll: "搜索全部",
	search: "搜索",
	distance: "距离",
	grade: "星级",
	modify: "修改",
	totalInstallers: "共 {length} 个安装商",
	sortByDistance: "按距离排序",
	sortByGrade: "按星级排序",

	tips: {
		deleteInstaller: "确定删除该安装商吗？",
		copySuccess: "复制成功",
		enableInstaller: "确定启用该安装商吗？",
		disableInstaller: "确定禁用该安装商吗？",
		deleteOption: "确定删除该选项吗？"
	},

	rules: {
		name: "名称是必填项",
		country: "国家是必填项",
		city: "城市是必填项",
		state: "州是必填项",
		address: "地址是必填项",
		zipCode: "邮政编码是必填项",
		longitude: "经度是必填项",
		latitude: "纬度是必填项",
		star: "星级是必填项",
		serviceArea: "服务区域是必填项",
		status: "状态是必填项",
		phone: "电话是必填项",
		email: "电子邮件是必填项",
		job: "工作是必填项",
		remark: "备注是必填项",
		contacts: "联系人是必填项",
		emailCorrect: "电子邮件格式不正确",
		geocoderNotFound: "Google 地理经纬度未找到"
	}
};
