export default {
	jobTitle: "Job Title",
	toReview: "To Review",
	jobCategory: "Job Category",
	jobType: "Job Type",
	location: "Location",
	experience: "Experience",
	workMode: "Work Mode",
	releaseTime: "Release Time",
	remote: "Remote",
	onSite: "On-Site",
	hybrid: "Hybrid",
	closed: "Closed",
	published: "Published",
	pin: "Pin",
	unpin: "Unpin",
	publish: "Publish",
	unpublish: "Unpublish",
	jobSummary: "Job Summary",
	jobResponsibilities: "Job Responsibilities",
	qualificationsAndExperience: "Qualifications & Experience",
	compensationAndBenefits: "Compensation & Benefits",
	viewResume: "View Resume",
	country: "Country",
	selectLocation: "Select Location",

	rules: {
		title: "Title is required.",
		type: "Type is required.",
		category: "category is required.",
		country: "country is required.",
		experience: "experience is required.",
		jobOffice: "job office is required.",
		positionSummary: "position summary is required.",
		lengthCheck: "Length should be {min} to {max}",
		responsibilities: "responsibilitiesis required.",
		qualificationsAndExperience: "qualifications and experience is required.",
		compensationAndBenefits: "compensation and benefits is required."
	},

	applyTime: "Apply Time",
	resume: "Resume",
	profileLink: "Profile Link"
};
