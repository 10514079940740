export default {
	installerName: "Installer Name",
	zipcode: "Zip Code",
	country: "Country",
	state: "State",
	city: "City",
	detailsAddress: "Details Address",
	star: "Star",
	updateTime: "Update Time",
	status: "Status",
	location: "Location",
	import: "Import",
	baseInfo: "Base Info",
	name: "Name",
	address: "Address",
	longitude: "Longitude",
	latitude: "Latitude",
	serviceArea: "Service Area",
	remark: "Remark",
	contact: "Contact",
	phone: "Phone",
	email: "Email",
	job: "Job",
	explanation: "Explanation",
	downloadTemplate: "Download Template",
	uploadFile: "Upload File",
	successImport: "Import Success",
	continueImport: "Continue Import",
	backToList: "Back to List",
	importError: "Import Error",
	errorPosition: "Error Position",
	explanationList: {
		line1: "After downloading the template, please fill in the installer information in strict accordance with the template.",
		line2: "Do not modify the template document format,cell format,form title content.",
		line3: "If the upload fails, please check whether the content of the document is accurate.",
		line4: "If the installer is repeated (judged by name + Zip code), the latest uploaded data will be used.",
		line5:
			"If there are multiple contact information for the same installer, they should be separated by commas in English format in the contact name, phone number, position,and email cells.",
		line6: "Imported installers are enabled by default."
	},
	us: "United States",
	au: "Australia",
	nz: "New Zealand",
	ca: "Canada",
	serviceScope: "Service Scope",
	findInstaller: "Find Installer",
	searchByCountry: "Search by Country",
	zipCode: "Zip Code",
	searchByAddress: "Search by Address",
	searchByZipcode: "Search by Zip Code",
	searchByDistance: "Search by Distance",
	km: "Kilometers",
	miles: "{distanceMiles} Miles",
	searchByGrade: "Search by Grade",
	searchAll: "Search All",
	search: "Search",
	distance: "Distance",
	grade: "Grade",
	modify: "Modify",
	totalInstallers: "A total of {length} installers",
	sortByDistance: "Sort by Distance",
	sortByGrade: "Sort by Grade",

	tips: {
		deleteInstaller: "Are you sure you want to delete this installer?",
		copySuccess: "Copy Success",
		enableInstaller: "Are you sure you want to enable this installer?",
		disableInstaller: "Are you sure you want to disable this installer?",
		deleteOption: "Are you sure you want to delete this option?"
	},

	rules: {
		name: "Name is required.",
		country: "Country is required.",
		city: "City is required.",
		state: "State is required.",
		address: "Address is required.",
		zipCode: "Zip Code is required.",
		longitude: "Longitude is required.",
		latitude: "Latitude is required.",
		star: "Star is required.",
		serviceArea: "Service area is required.",
		status: "Status is required.",
		phone: "Phone is required.",
		email: "Email is required.",
		job: "Job is required.",
		remark: "Remark is required.",
		contacts: "Contacts in required.",
		emailCorrect: "Email format is incorrect.",
		geocoderNotFound: "Google Geocoder Not Found."
	}
};
