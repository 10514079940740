export default {
	jobTitle: "职位",
	toReview: "待查看",
	jobCategory: "职位类别",
	jobType: "职位类型",
	location: "工作地点",
	experience: "经验",
	workMode: "办公类型",
	releaseTime: "发布时间",
	remote: "远程",
	onSite: "现场",
	hybrid: "混合",
	closed: "已关闭",
	published: "已发布",
	pin: "置顶",
	unpin: "取消置顶",
	publish: "发布",
	unpublish: "取消发布",
	jobSummary: "职位概要",
	jobResponsibilities: "职位职责",
	qualificationsAndExperience: "任职资格",
	compensationAndBenefits: "薪酬福利",
	viewResume: "查看简历",
	country: "国家",
	selectLocation: "选择地点",

	rules: {
		title: "标题是必填的。",
		type: "类型是必填的。",
		category: "类别是必填的。",
		country: "国家是必填的。",
		experience: "经验是必填的。",
		jobOffice: "职位办公室是必填的。",
		positionSummary: "职位概述是必填的。",
		lengthCheck: "长度应为 {min} 到 {max}。",
		responsibilities: "职责是必填的。",
		qualificationsAndExperience: "资格和经验是必填的。",
		compensationAndBenefits: "薪酬福利是必填的。"
	},

	applyTime: "申请时间",
	resume: "简历",
	profileLink: "个人链接"
};
