export default {
	add: "Add",
	modify: "Change",
	username: "Username",
	password: "Password",
	confirmPassword: "Confirm password",
	name: "Name",
	status: "Status",
	role: "Role",
	remark: "Remark",
	lastIp: "Most recent login ip",
	loginCount: "Login times",
	lastLoginTime: "Most recent login time",
	operate: "Operate",
	menuName: "Name of menu",
	menuAddress: "Address of menu",
	menuType: "Type of menu",
	parent: "Parent",
	updateTime: "Update time",
	chineseName: "Chinese name",
	englishName: "English name",
	path: "Route",
	icon: "Icon",
	sort: "Sort by",
	standalone: "Whether to display independently",
	isDirectory: "Directory",
	permissionCodeOrPath: "Permission code/Address",
	permissionCodeName: "Permission code/Address name",
	yes: "Yes",
	no: "No",
	showType: "Display types",
	public: "Public",
	login: "Login",
	permission: "Permission",
	accessType: "Type of access",
	addBy: "Add by",
	addTime: "Add time",
	edit: "Edit",
	delete: "Delete",
	modifyPassword: "Change password",
	enable: "Enable",
	disabled: "Disable",
	universalAccess: "Public",
	loginAccess: "Login",
	permissionAccess: "Permission",
	permissionCode: "Permission code",
	introduce: "Introduce",
	ip: "IP",
	createUser: "Create user",
	createAt: "Creation time",
	updateAt: "Update time",
	keywords: "Keywords",
	belongsDepartment: "Department",
	crmID: "crmID",
	crmName: "CRM Name",
	crmRole: "CRM Role",
	position: "Position",
	bindingStatus: "Binding Status",
	unbound: "Unbound",
	bound: "Bound",
	bindFuzzyName: "Search by Username, Name",
	fuzzyName: "Search by Username, ID, Employee ID",
	department: "Department",
	departmentLeader: "Department Leader",
	jobNumber: "Employee ID",
	boundCRMAccount: "Bound CRM Account",
	toBinding: "To Bind",
	parentDepartment: "Parent Department",
	departmentName: "Department Name",
	sortOrder: "Sort Order",
	bind: "Bind",
	bindPlateformAccount: "Link Platform Account",
	bindingAccount: "Binding Account",
	choosePlateformAccount: "Choose Platform Account",
	confirmBinding: "Confirm Binding",
	plateformAccountInfo: "Platform Account Info",
	crmAccountInfo: "CRM Account Info",
	organizationTree: "Organization Tree",
	filterWords: "Filter Keywords",

	rules: {
		username: "User name is required ",
		password: "Password is required",
		passwordRegExp: "At least 8-16 characters, 1 uppercase letter, 1 lowercase letter and 1 number",
		name: "Name is required",
		status: "Status is required",
		mustNumber: "Sort by is required",
		isStandalone: "Independent display or not is required",
		isDirectory: "Is Directory is required.",
		title: "Chinese name is required",
		enTitle: "English name is required",
		path: "Route is required",
		parent: "Parent is required",
		icon: "Icon is required",
		sort: "Sort by is required",
		standalone: "Independent display or not is required",
		permissionCodeOrPath: "Permission code or route is required",
		accessType: "Access type is required",
		introduce: "Introduction is required",
		permissionCode: "Permission code is required",
		ip: "IP is required"
	},
	createSuccess: "Created successfully",
	modifySuccess: "Modified successfully",
	refreshedAfter: "Page will redirect after 2 seconds",
	message: {
		modifySuccess: "Modified successfully"
	},
	warning: "Warning",
	installCertification: "Installer Certification",
	saleCertification: "Sales Certification",
	designCertification: "Designer Certification",
	completedCertification: "Number of persons certified",
	backToList: "Back to list",
	transferCompleted: "Transfer completed",
	transferFailed: "Reasons for failure: {msg}",
	accountId: "Account ID",

	transfer: {
		title: "Transfer instructions.",
		line1:
			"After the transfer is completed, the new account will obtain the certificate that the old account has obtained, as well as the permissions corresponding to the certificate, such as device installation and activation permissions.",
		line2: "The valid start time of the certificate of the new account will start from the time when the transfer is completed.",
		line3: "The old account's course learning records, exam results and other data will not be transferred.",
		line4: "The transfer operation is non-reversible, please be careful!"
	},

	company: {
		companyName: "Company Name",
		companyNO: "Company Number",
		companyType: "Company Type",
		installer: "Installer",
		distributor: "Distributor",
		operator: "Operator",
		certificationStatus: "Certification status",
		installCertificationStatus: "Install Certificate Status",
		baseInfo: "Base Information",
		companyFullName: "Company Full Name",
		companyEmail: "Company Email",
		companyPhoneNumber: "Company Phone Number",
		country: "Country",
		state: "State",
		city: "City",
		zipCode: "Zip Code",
		street: "Street",
		proxyPersonalInfo: "Proxy Personal Information",
		proxyPersonalName: "Proxy Personal Name",
		proxyPersonalEmail: "Proxy Personal Email",
		proxyPersonalPhoneNumber: "Proxy Personal Phone Number",
		position: "Job Position",
		businessInformation: "Business Information",
		businessHours: "How long the company has been in business",
		serviceArea: "Service Area",
		staffSize: "How many employees are in your company",
		electriciansNumber: "Number of in-house electricians",
		contractorNumber: "Contractor license No.",
		noContractorReason: "Without contractor license reason",
		isAssociateDistributor: "Is there an affiliated business role",
		companyWebsite: "Company Website",
		year: "Year",
		years: "Years",
		enableMatch: "Matching leads",
		leadsDispatch: "Leads Dispatch",
		star: "Star",
		longitude: "Longitude",
		latitude: "Latitude",
		remark: "Remark",
		leadsManager: "Leads Manager",
		sales: "Sales",
		newLeadsNotice: "New Leads Notice",
		sysAdmin: "System Admin",
		certificateYes: "Certified",
		certificateNo: "Unauthenticated",
		salesManager: "Sales Manager",
		dataStatistics: "Data statistics"
	},

	tips: {
		deleteMenu: "This action will permanently delete the menu, want to continue?",
		deletePermissionCode: "This action will permanently delete the permission/address, want to continue?",
		deleteRole: "This action will permanently delete the permission code/address, want to continue?",
		deleteIp: "This action will permanently delete the IP, want to continue?",
		manualAuthentication: "Whether to confirm manual authentication",
		moreThenZero: "Sorting must be integers that are greater than or equal to 0 and less than 100.",
		distanceLimit: "Positive integer, up to 5 digits",
		distanceMinLimit: "The distance must be greater than or equal to 0",
		maxValueFiveLimit: "Please enter a positive integer not exceeding 5 digits",
		confirmDisabledOrganization: "Confirm disabling this organization?",
		confirmEnableOrganization: "Confirm enabling this organization?",
		confirmDeleteOrganization: "Confirm deleting this organization?",
		parentDepartment: "Please select a parent department",
		departmentName: "Please enter the department name",
		sortOrder: "Please enter the sort order",
		sortOrderPlaceholder: "The smaller the number, the higher the priority",
		deleteWarning: "There are personnel in this department, please transfer them before {typeText}",
		pleaseChoosePlateformAccount: "Please select a platform account",
		hasChildDepartment: "There are already sub-departments under this department. Please delete the sub-department first."
	},
	userList: {
		loginAccount: "Login Account",
		accountStatus: "Account Status",
		accountType: "Account Type",
		accountLevel: "Account Level",
		companyName: "Company Name",
		companyCode: "Company Code",
		userRole: "User Role",
		agingType: "Aging Type",
		userName: "User Name",
		phone: "Phone",
		email: "Email",
		address: "Address",
		createTime: "Create Time",
		lastLoginTime: "Last Login Time",
		searchPlaceholder: "Login account, email, company",
		internal: "Internal",
		external: "External",

		jobNo: "job number",
		departmental: "Departmental",
		country: "Country",
		account: "Account"
	},
	qualificationTransfer: {
		transferTime: "Transfer Time",
		viewRecord: "View Record",
		add: "Add",
		change: "Change",
		oldAccount: "Old Account",
		newAccount: "New Account",
		transfer: "Transfer",
		backToChange: "Back To Change",
		confirmTransfer: "Confirm Transfer",
		transferConfirm: "Certification Transfer Confirm",
		addAccountRule: "Please add account",
		radio: "Radio",
		sameAccount: "The old account and the new account cannot be the same account!",
		operateTime: "Operate Time",
		operateUserName: "Operator Username",
		operateName: "Operate Name",
		transferRecord: "Transfer Record"
	}
};
