export default [
	{
		path: "/installer-map",
		name: "installer-map",
		meta: {
			icon: "map",
			title: "安装商地图",
			enTitle: "",
			isLink: "",
			isHide: false,
			isFull: false,
			isAffix: true,
			isKeepAlive: true
		},
		children: [
			{
				path: "/installer-map/list",
				name: "installerList",
				component: "/installer-map/list",
				meta: {
					icon: "list",
					title: "列表",
					enTitle: "Installer List",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-map/create",
				name: "installerMapCreate",
				component: "/installer-map/detail",
				meta: {
					icon: "map",
					title: "创建",
					enTitle: "Installer Create",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-map/edit",
				name: "installerMapEdit",
				component: "/installer-map/detail",
				meta: {
					icon: "edit",
					title: "编辑",
					enTitle: "Installer Edit",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-map/detail",
				name: "installerMapDetail",
				component: "/installer-map/detail",
				meta: {
					icon: "detail",
					title: "详情",
					enTitle: "Installer Detail",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-map/import",
				name: "installerMapImport",
				component: "/installer-map/import",
				meta: {
					icon: "import",
					title: "详情",
					enTitle: "Installer Import",
					isLink: "",
					isHide: true,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			},
			{
				path: "/installer-map/map",
				name: "installerMap",
				component: "/installer-map/map",
				meta: {
					icon: "map2",
					title: "地图",
					enTitle: "Map",
					isLink: "",
					isHide: false,
					isFull: false,
					isAffix: true,
					isKeepAlive: true
				}
			}
		]
	}
];
